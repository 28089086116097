<template>
  <v-card rounded="0">
    <v-card rounded="0" color="#f3f2ed" class="pt-4">
      <v-container>
        <v-card>
          <v-container>
            <v-container>
              <h4 class="h35 text-center" style="color:#185454">Mission of Mupa Orphanage & Boarding School</h4>
              <h4 class="h4 text-center pb-2" style="color:#185454">located in the Province of Cunene in Southern Angola</h4>
              <v-divider />
              <v-row>
                <v-col cols="12" lg="6">
                  <h5 class="h5 my-2">In 2009, Hope and Angels Foundation began caring for children at Mupa Orphanage/Boarding School (Missao de Mupa). This Mission is run by the Holy Sisters of Mupa and is supported by the Hope and Angels Foundation. Several of the children living at the school have been physically/sexually abused and some of them are victims of human trafficking. This orphanage/boarding school is located in the Province of Cunene. Due to extreme drought conditions, it’s an area of Angola where the people experience severe famine.</h5>
                  <h5 class="h5 my-2">Since 2009, Hope and Angels has been providing these children with shelter, clothes, shoes and two meals each day. In addition, the Foundation gives them the opportunity to receive an education (up to Grade 9). This is something that’s extremely difficult for most children of Angola to attain. It’s the very generous donations the Foundation receives from its faithful donours that makes all of this possible.</h5>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-carousel
                    class="pa-0"
                    cycle
                    height="300"
                    :show-arrows-on-hover="true"
                    :hide-delimiters="true"
                    delimiter-icon="mdi-minus"
                  >
                    <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      fade
                      eager
                    >
                      <v-img :src="item.src" eager/>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
              <h5 class="h5 my-2">Three income-generating projects have been established at the orphanage/boarding school:</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Growing vegetables</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Baking Cookies</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Operating a small convenience-type store</h5>
              <h5 class="h5 my-2">The children have learned how to grow vegetables and bake cookies. They sell these commodities to those living in the community of Mupa. In addition, a small convenience store is now operational at the school. This store, which is the only one in the area, sells food supplies such as  rice, sugar, salt, oil, cornmeal, and beverages to villagers. These three enterprises give the children a means by which they can generate some income for the Mupa Orphanage/Board School.</h5>
              <h5 class="h5 my-2">The only water source for the orphanage/boarding school and community of Mupa is the local river. The water flowing in the river, however, is contaminated with deadly bacteria. Children are particularly vulnerable to illnesses and death caused by drinking this contaminated water. In 2015, the Foundation received a water treatment system, capable of filtering 200 liters/hour, and 10 individual emergency water filters from a generous benefactor. So, since 2015, contaminated river water has been purified at the orphanage/boarding school. This has resulted in a big reduction in illnesses and fatalities among the children. It is the goal of the Foundation, however, to have a well dug on site. This well would provide potable water for the children as well as the residents of the community. Even though the school has a water treatment system, the children are required to walk 7 km every morning to get water from the river. This water is then filtered for their consumption. If a well was established on site, the children would no longer face the arduous task of walking in the intense heat every day to get the water they need.</h5>
            </v-container>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/mupa/Picture1.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture2.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture3.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture4.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture5.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture6.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture7.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture8.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture9.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture10.jpg')
        },
        {
          src: require('@/assets/images/mupa/Picture11.jpg')
        }
      ]
    }
  }
}
</script>