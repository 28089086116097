<template>
  <div>
    <TopParallax
      title="Mupa & Cunene Province"
      :img-src="imgSrc"
    />
    <ChildrenBar />
    <MupaContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import ChildrenBar from '@/components/hope/ChildrenBar.vue'
import MupaContent from '@/components/hope/MupaContent.vue'

export default {
  components: {
    TopParallax,
    ChildrenBar,
    MupaContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide2.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Mupa'
      }
    }
  }
}
</script>
